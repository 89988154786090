import React from "react";
import "../_static/css/game.scss";
import headliceWordmark from "../_static/images/headlice-wordmark-red.png";
import trailer from "../_static/videos/Headlice-Announcement-Trailer.mp4";
import placeholder from "../_static/images/Headlice-Announcement-Trailer.png";
import screenshot1 from "../_static/images/Screenshot-1.png";
import screenshot2 from "../_static/images/Screenshot-2.png";
import screenshot3 from "../_static/images/Screenshot-3.png";
import screenshot4 from "../_static/images/Screenshot-4.png";
import screenshot5 from "../_static/images/Screenshot-5.png";
import screenshot6 from "../_static/images/Screenshot-6.png";

const HeadliceView = () => {
    return (<div className="game-page">
            <div className="page-header">
                <img src={headliceWordmark} width="50%" height="auto" alt="Headlice logo" className="game-wordmark"/>
            </div>
            <div className="game-tagline">
                <p>Jump, Grapple, Swing, and <em>chomp</em></p>
            </div>
            <div className="container pt-6">
                <div className="columns">
                    <div className="content">
                        <video preload="true" controls loop poster={placeholder}>
                            <source src={trailer} type="video/mp4"/>
                        </video>
                        <div className="mt-3 mb-6">
                            <a href="https://store.steampowered.com/app/2851800/Headlice" target="_blank"
                               className="wishlist">Wishlist
                                on Steam</a>
                        </div>
                        <p>Entering into a niche market where you play as the monster - Bunguin Games launching it’s
                            debut
                            title “Headlice” - a satirical body-horror singleplayer experience. In a corporate
                            hellscape,
                            one company has pushed the limits of science and morality to create an array of biological
                            creatures, engineered for the sole purpose of brewing the perfect cup.</p>
                        <p>Jump, grapple, swing and chomp your way through offices, labs, warehouses and processing
                            units in
                            a physics-based body horror experience. Discover new biological abilities, and uncover the
                            depravity of The Coffee Corp as you make your way deeper in to the facility - driven by
                            instinct
                            and a need for fresh blood.</p>
                    </div>
                </div>
            </div>
            <div className="game-tagline mt-6">
                Gallery
            </div>
            <div className="screenshots">
                <div className="container">
                    <div className="columns">
                        <div className="column is-4">
                            <img src={screenshot1} alt="Game screenshot"/>
                        </div>
                        <div className="column is-4">
                            <img src={screenshot2} alt="Game screenshot"/>
                        </div>
                        <div className="column is-4">
                            <img src={screenshot3} alt="Game screenshot"/>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-4">
                            <img src={screenshot4} alt="Game screenshot"/>
                        </div>
                        <div className="column is-4">
                            <img src={screenshot5} alt="Game screenshot"/>
                        </div>
                        <div className="column is-4">
                            <img src={screenshot6} alt="Game screenshot"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="game-footer mt-6">
                Be the crab. Become the host
            </div>
        </div>
    );
};

export default HeadliceView;