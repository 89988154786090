import React from "react";
import '../../_static/css/footer.scss'
import logo from "../../_static/images/bg-hori-dark.png";
import {Link} from "react-router-dom";



const Footer = () => {
    return (
        <div className="footer-section">
            <div className="container">
                <div className="columns">
                    <div className="column is-6">
                        <a href="https://bunguin.games"><img src={logo} className="footer-logo" alt="Bunguin Games logo" /></a>
                    </div>
                </div>
                <div className="has-text-centered copyright">
                    (c) 2022-2024 Bunguin Games
                </div>
            </div>
        </div>
    );
};

export default Footer;