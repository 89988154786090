import React from 'react';
import '../_static/css/index.scss';
import Footer from "../component/footer/Footer";
import HeadliceView from "../view/HeadliceView";

function App() {
    return (
        <>
            <HeadliceView/>
            <Footer/>
        </>
    );
}

export default App;
